<template>
  <div class="pack-box" :class="`pack-box${copyright.entrantsType}`">
    <div class="gouBack" @click="goBack">
      <i class="iconfont el-icon-arrow-left" />
      {{ "返回资料包列表" }}
    </div>
    <div class="bigBox">
      <div v-for="(item, index) in packageList" :key="index" class="boxItem">
        <div class="contentItem">
          <div v-if="IsPurchase == 0" class="seeBtn" />
          <div class="sourceImg">
            <img v-if="item.filetype == 'excel'" class="imgItem" src="@/assets/img/study/dataXls.png">
            <img v-else-if="item.filetype == 'word'" class="imgItem" src="@/assets/img/study/dataDoc.png">
            <img v-else-if="item.filetype == 'pdf'" class="imgItem" src="@/assets/img/study/dataPdf.png">
            <img v-else-if="item.filetype == 'ppt'" class="imgItem" src="@/assets/img/study/dataPpt.png">
            <img v-else class="imgItem" src="@/assets/img/study/dataFile.png">
          </div>
          <div class="contentBox">
            <div class="contentTitle">{{ item.title }}</div>
            <div class="contentTips">{{ item.size }}</div>
          </div>
        </div>
        <div v-if="IsPurchase == 1 && item.seeNum && item.seeNum > 0 && userInfo" class="btnItem" @click="goTry(item)">
          免费试看
        </div>
        <div class="dis_flex">
          <div v-if="IsPurchase == 0" class="kowledge_Btn" @click="openKowledge(item)">
            知识点
          </div>
          <div v-if="IsPurchase == 0" class="dowBtn" @click="dowInfo(item)">
            下载
          </div>
        </div>
      </div>
    </div>
    <div class="brace" />
    <div v-if="IsPurchase == 1" class="buyBox">
      <div class="priceBox">
        <div class="buyBtnBox">
          <div class="price">
            <span class="symbol">￥</span>
            <span class="num">{{ price }}</span>
          </div>
          <div class="buyBtn" @click="goOrder">立即购买</div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose" :show-close="false">
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4" />
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo, getToken } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import { getDomain } from '@/api/cookies'
import kowledgePoints from '@/components/kowledgePoints.vue'
export default {
  components: {
    kowledgePoints
  },
  data() {
    return {
      copyright: {}, /* { name: "同行转型", key: 1 },    { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */
      id: null, // 资料包ID
      title: null, // 资料包名称
      schoolId: null, // 大类ID
      userInfo: null,
      packageList: null, // 资料列表
      IsPurchase: null, // 1 未购买 0 已购买
      price: null,
      kowledgePointsVisible: false
    }
  },
  async created() {
    this.copyright = getDomain()
    this.id = this.$route.query.id
    this.userInfo = getInfo()

    this.getPackageDetails()
    this.title = this.$route.query.title
    this.IsPurchase = this.$route.query.isPurchase
    this.price = this.$route.query.num
  },
  methods: {

    goBack() {
      this.$router.go(-1)
    },
    /* 资料详情 */
    getPackageDetails() {
      const that = this
      // if (this.userInfo) {
      dataClient
        .getPackageDetails(10000, 1, this.id, this.userInfo && this.userInfo.id ? this.userInfo.id : undefined)
        .then((res) => {
          for (const item of res.rows) {
            item.filetype = Vue.prototype.getFileType(item.names)
            Vue.prototype.getFilesize(item.names, function (size) {
              const sizeVal = (size / 1028).toFixed(0) + 'k'
              const data = (size / 1028 / 1024).toFixed(0)
              item.size = data > 0 ? data + 'M' : sizeVal
              that.$forceUpdate()
            })
          }
          this.packageList = res.rows
        })
      // }
    },
    /* 查看 */
    goTry(item) {
      this.$router.push({
        path: '/VIP/sourcePage',
        query: {
          seeNum: item.seeNum,
          names: item.names,
          id: this.id,
          title: this.title,
          schoolId: this.schoolId,
          type: 2,
          IsPurchase: this.IsPurchase != 1,
        }
      })
    },
    goOrder() {
      const token = getToken()
      if (token) {
        orderClient
          .addOrders(
            this.id,
            localStorage.getItem('userId'),
            this.title,
            5,
            this.title
          )
          .then((res) => {
            if (res.code == 0) {
              /*   this.users = res.msg; */
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    /* 下载资料 */
    dowInfo(item) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.IsPurchase == 0) {
        if (item.names) {
          const data = {
            zlId: item.mid,
            xmId: item.kId,
            dlId: item.pid,
            linkLocation: item.names,
            type: 1
          }
          dataClient.downloadRecord(data)
          Vue.prototype.DownloadDoc(item.names, item.title)
        } else {
          this.$notify({
            title: '错误',
            message: '暂无资源',
            type: 'error',
            duration: 2000
          })
        }
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法查看, 请先去购买',
          type: 'error',
          duration: 2000
        })
      }
    },
    openKowledge(item) {
      this.kowledgePointsVisible = true
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.mid)
      })
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.gouBack {
  color: #666666;
  font-size: 14px;
  cursor: pointer;
  width: 1220px;
  margin: 20px auto;
}

.bigBox {
  position: relative;
  width: 1220px;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 80px;

  .boxItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 104px;
    border-bottom: 1px solid #eeeeee;

    .contentItem {
      display: flex;
      align-items: center;
      position: relative;

      .seeBtn {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .sourceImg {
        .imgItem {
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
      }

      .contentBox {
        .contentTitle {
          color: #333333;
          margin-bottom: 12px;
        }

        .contentTips {
          font-size: 12px;
          color: #a78888;
        }
      }
    }

    .btnItem {
      position: absolute;
      right: 32px;
      width: 88px;
      height: 30px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      color: #666666;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }

    .kowledge_Btn {
      width: 74px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #dddddd;
      color: #666666;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      margin-right: 20px;
      cursor: pointer;
    }

    .dowBtn {
      width: 60px;
      height: 30px;
      border: 1px solid #ff5e51;
      border-radius: 4px;
      color: #ff5e51;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }
}

.brace {
  width: 100%;
  height: 88px;
}

.buyBox {
  width: 100%;
  height: 88px;
  background-color: #ffffff;
  box-shadow: 0px -4px 8px 1px rgba(204, 204, 204, 0.20000000298023224);
  position: absolute;
  bottom: 270px;

  .priceBox {
    width: 1220px;
    height: 88px;
    margin: auto;
    display: flex;
    justify-content: end;

    .buyBtnBox {
      display: flex;
      align-items: center;

      .price {
        margin-right: 20px;

        .symbol {
          font-size: 18px;
          color: #ff5e51;
        }

        .num {
          font-size: 32px;
          color: #ff5e51;
        }
      }

      .buyBtn {
        width: 170px;
        height: 48px;
        background-color: #ff5e51;
        color: #ffffff;
        text-align: center;
        line-height: 48px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

.pack-box {
  position: relative;
  min-height: 80vh;
  height: 100%;

  .buyBox {
    bottom: 0px !important;
  }
}

.pack-box3 {
  .buyBox {
    bottom: -20px !important;
  }
}

/*.pack-box1 {
  .buyBox {
    bottom: 440px !important;
  }
} */
.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
</style>
